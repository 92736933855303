/* eslint-disable no-useless-escape */
import { FC } from "react";
import { GameStatus } from "../utils/types";

type Props = {
  gameStatus: GameStatus;
};

const GameStatusEmoticon: FC<Props> = (props) => {
  const { gameStatus } = props;
  return (
    <div className="game-status-emoticon">
      {gameStatus === "won" && <pre>＼（Ｔ∇Ｔ）／</pre>}
      {gameStatus === "lost" && <pre>( ・_・)ノ</pre>}
    </div>
  );
};

export default GameStatusEmoticon;
