import Title from "../components/Title";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-center flex-column align-items-center">
      <Title />
      <p>Sorry! The route you are looking for has not been found :(</p>
      <button className="btn btn-lg" onClick={() => navigate("/")}>
        Go back to main page
      </button>
    </div>
  );
};

export default NotFound;
