import { useState } from "react";
import { GameStatus } from "../utils/types";
import Title from "../components/Title";
import Greetings from "../components/Greetings";
import Board from "../components/Board";

const Root = () => {
  const [gameStatus, setGameStatus] = useState<GameStatus>("playing");

  const restartTheGame = () => {
    window.location.reload();
  };

  return (
    <>
      {gameStatus === "playing" && (
        <Board difficultyLevel={"hard"} updateGameStatus={setGameStatus} />
      )}
      {gameStatus !== "playing" && (
        <div className="d-flex flex-column align-items-center">
          <Title />
          <button
            onClick={() => restartTheGame()}
            className="btn btn-lg fade-in"
          >
            Try the game again!
          </button>
          <Greetings gameStatus={gameStatus} />
        </div>
      )}
    </>
  );
};

export default Root;
