import { hexToRgbA, randomIntRange } from "./common";

export const generateRandomColor = (): string => {
  const min = 6;
  const max = 10;
  const base = 16;

  const red = randomIntRange(min, max);
  const green = randomIntRange(min, max);
  const blue = randomIntRange(min, max);

  return `#${red.toString(base)}${green.toString(base)}${blue.toString(base)}`;
};

export const setGlobalVariables = (): void => {
  const randomColor = generateRandomColor();
  document.documentElement.style.setProperty(
    "--app-primary-color",
    randomColor
  );
  document.documentElement.style.setProperty(
    "--app-primary-color-light",
    hexToRgbA(randomColor)
  );
  document.documentElement.style.setProperty(
    "--app-primary-color-lighter",
    hexToRgbA(randomColor, 0.15)
  );
  document.documentElement.style.setProperty(
    "--app-primary-color-lightest",
    hexToRgbA(randomColor, 0.05)
  );
};

const initiateGlobalCssVariables = () => setGlobalVariables();

export default initiateGlobalCssVariables;
