import { FC, MouseEventHandler } from "react";

type defaultProps = {
  /**
   * Whether to show the card content or not
   */
  revealed: boolean;
};

type Props = {
  /**
   * number to render as card's value
   */
  cardNumber: number;

  /**
   * function to handle click event of the card number
   * either to reveal the card number or handle wrong element
   */
  cardClickHandler: MouseEventHandler<HTMLDivElement>;
} & Partial<defaultProps>;

const Card: FC<Props> = ({ revealed = true, cardNumber, cardClickHandler }) => {
  return (
    <div
      onClick={(e) => cardClickHandler(e)}
      className={`card ${!revealed && cardNumber ? "card-hidden" : ""}`}
      data-testid="card-element"
    >
      <span data-testid="card-text-element" className="card-text">
        {cardNumber ? cardNumber : ""}
      </span>
    </div>
  );
};

export default Card;
