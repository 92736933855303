import {
  faCodepen,
  faGithub,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const SocialLinks = () => {
  const [socialLinks] = useState([
    {
      url: "https://github.com/mhdmhsni",
      name: "Github",
      icon: faGithub,
    },
    { url: "https://codepen.io/mhdmhsni", name: "Codepen", icon: faCodepen },
    { url: "https://x.com/mhdmhsni", name: "X", icon: faXTwitter },
    {
      url: "https://linkedin.com/in/mhdmhsni",
      name: "LinkedIn",
      icon: faLinkedin,
    },
  ]);

  return (
    <ul className="social-links">
      {socialLinks.map((el, key) => (
        <li key={key}>
          <a
            className="btn btn-link"
            href={el.url}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon size="2x" icon={el.icon} />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default SocialLinks;
